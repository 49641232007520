

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bg-custom {
  background-color: #337ab7!important;
}

.text-custom {
  color: #337ab7!important;
}

.background-size-cover {
  background-size:cover;
}
.custom-img-border{
  padding: 5px;
  border: 2px solid #347ab7;
  background: #a9cae6;
}
.footer-banner img {
  width: 120px;
  margin: 5px 6px;
  height: 70px;
  padding: 0px;
}
 .copyright {
  width: 100%;
  text-align: center;
  margin: 5px 0px 20px 0px;
}